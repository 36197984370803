import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'

export const breakpoints = {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)',
	large: '@media (min-width: 1440px)',
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp
export const large = breakpoints.large

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
	@keyframes pulse {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	body {
		font-family: 'Suisse Intl', sans-serif;
		font-size: 20px;
		line-height: 24px;
		background-color: var(--black);
		${tablet} {
			font-size: 17px;
			line-height: 21px;
		}
	}

	:root {
		--white: #ffffff;
		--black: #000000;
		--pink: #ec008c;
		--grey: #dedede;
		--light-pink: #ffcdeb;
		--xxs: 5px;
		--xs: 10px;
		--s: 20px;
		--m: 30px;
		--l: 40px;
		--xl: 50px;
		--xxl: 80px;
		--xxxl: 100px;
		--xxxxl: 150px;
		--xxxxxl: 250px;
	}

	${tablet} {
		:root {
			--xxs: 3px;
			--xs: 7px;
			--s: 15px;
			--m: 20px;
			--l: 30px;
			--xl: 35px;
			--xxl: 40px;
			--xxxl: 60px;
			--xxxxl: 100px;
			--xxxxxl: 100px;
		}
	}

	p {
		margin-bottom: 1em;
		&:last-child {
			margin-bottom: 0;
		}
	}

	h1,
	.h1 {
		font-size: 64px;
		line-height: 66px;
		${tablet} {
			font-size: 39px;
			line-height: 41px;
		}
	}

	h2,
	.h2 {
		font-size: 24px;
		line-height: 31px;
		${large} {
			font-size: 34px;
			line-height: 40px;
		}
	}

	.logo-size {
		font-size: 32px;
		line-height: 1.04;
		${mobile} {
			font-size: 27px;
		}
	}

	.sm {
		font-size: 20px;
		line-height: 25.9px;
	}

	.xs {
		font-size: 20px;
		line-height: 1;
		${tablet} {
			font-size: 14px;
		}
	}

	.sans {
		font-family: 'Suisse Intl', sans-serif;
		font-size: 32px;
		font-size: 32px;
		line-height: 38px;
		${tablet} {
			font-size: 24px;
			line-height: 30px;
		}
	}

	.sans-m {
		font-family: 'Suisse Intl', sans-serif;
		font-size: 30px;
		line-height: 36px;
		${tablet} {
			font-size: 22px;
			line-height: 28px;
		}
	}

	.sans-sm {
		font-family: 'Suisse Intl', sans-serif;
		font-size: 20px;
		line-height: 28px;
		${tablet} {
			font-size: 17px;
			line-height: 21px;
		}
	}

	.sans-xs {
		font-family: 'Suisse Intl', sans-serif;
		font-size: 14px;
		line-height: 18px;
	}
`

export const InsetSection = styled(Section)`
	> div {
		padding-left: 110px;
	}
	${tablet} {
		> div {
			padding-left: 93px;
		}
	}
	${mobile} {
		> div {
			padding-left: 83px;
		}
	}
`

const reset = css`
	body {
		margin: 0px;
		/* min-height: 100%; */
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
		font-size: inherit;
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	ul,
	ol,
	blockquote {
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0.5em 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
	}

	button {
		-webkit-appearance: none;
		border: none;
		background: none;
		font-size: inherit;
		font-family: inherit;
		cursor: pointer;
		padding: 0;
		margin: 0;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button,
	option {
		color: inherit;
	}
`

export default GlobalStyles
