import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql, Link, navigate, useStaticQuery} from 'gatsby';
import styled from '@emotion/styled';
import Section from '~components/Section';
import {MrPlus, Positive} from '~components/Svg';
import MobileMenu from '~components/MobileMenu';
import {mobile, tablet} from '~styles/global';
import {useSiteState} from '../context/siteContext';
import {Plus} from '~components/Svg';
import Headroom from 'react-headroom';
import Transition from '~components/Transition';
import Hamburger from '~components/Hamburger';

const Header = ({className, fixedTitle, fadeTitle, blackPlus, fixedFooter}) => {
	const [siteState, setSiteState] = useSiteState();
	const [pinned, setPinned] = useState(true);
	const [top, setTop] = useState(false);
	const [bottom, setBottom] = useState(false);

	const data = useStaticQuery(graphql`
		{
			sanitySiteSettings {
				showNews
			}
			sanityArchivePage {
				slug {
					current
				}
			}
			sanityPost {
				slug {
					current
				}
			}
			sanityProduction {
				slug {
					current
				}
			}
			sanityDirectors {
				slug {
					current
				}
			}
			sanityPonyShow {
				slug {
					current
				}
			}
			sanityNews {
				slug {
					current
				}
			}
			sanityAbout {
				slug {
					current
				}
			}
		}
	`);

	useEffect(() => {
		if (window && window.pageYOffset < 100) {
			setTop(true);
		}
		if (window?.pageYOffset + (window?.innerHeight + 10) >= document.documentElement.offsetHeight) {
			setBottom(true);
		}
		window.addEventListener('scroll', () => {
			if (window.pageYOffset < 100) {
				setTop(true);
			} else {
				setTop(false);
			}
			if (window?.pageYOffset + (window?.innerHeight + 10) >= document.documentElement.offsetHeight) {
				setBottom(true);
			} else {
				setBottom(false);
			}
		});
		return () => {
			window.removeEventListener('scroll', () => {
				if (window.pageYOffset < 100) {
					setTop(true);
				} else {
					setTop(false);
				}
			});
		};
	}, []);

	const showNav = () => {
		if (fixedTitle) {
			return true;
		} else if (pinned) {
			return true;
		} else if (top) {
			return true;
		} else if (bottom) {
			return true;
		}
	};

	return (
		<>
			<Wrap className={className} sticky={!fixedTitle}>
				<Section>
					<Inner>
						<Left to="/">
							<LogoSplitOne black={blackPlus} />
							<Transition>
								<StickyContainer>
									{siteState.pageTitle ? (
										<>
											<PageTitle sticky={!fixedTitle} fadeTitle={fadeTitle} top={top} className="logo-size">
												{siteState.pageTitle}
											</PageTitle>
										</>
									) : (
										<>
											<LogoSplitTwo fadeTitle={fadeTitle} top={top} />
										</>
									)}
								</StickyContainer>
							</Transition>
						</Left>
						<Headroom
							disableInlineStyles
							onUnfix={() => {
								setPinned(true);
							}}
							onPin={() => setPinned(true)}
							onUnpin={() => {
								setPinned(false);
							}}
							downTolerance={20}
							upTolerance={20}
						>
							<Nav className="sm" show={showNav()}>
								<NavLink to={`/${data?.sanityArchivePage?.slug?.current}`} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>Projects</NavLinkText>
								</NavLink>
								<NavLink to={'/production'} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>Production</NavLinkText>
								</NavLink>
								<NavLink to={`/${data?.sanityPonyShow?.slug?.current}`} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>A-List Directors </NavLinkText>
								</NavLink>
								<NavLink to={`/${data?.sanityPost?.slug?.current}`} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>Post</NavLinkText>
								</NavLink>
								{/* <NavLink to={`/${data?.sanityDirectors?.slug?.current}`} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>Directors</NavLinkText>
								</NavLink> */}
								<NavLink to={'/ooh'} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>OOH</NavLinkText>
								</NavLink>
								<NavLink to={'/stills'} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>Stills</NavLinkText>
								</NavLink>
								{data.sanitySiteSettings?.showNews && (
									<NavLink to={`/${data?.sanityNews?.slug?.current}`} activeClassName="active">
										<NavLinkPlus />
										<NavLinkText>News</NavLinkText>
									</NavLink>
								)}
								<NavLink to={`/${data?.sanityAbout?.slug?.current}`} activeClassName="active">
									<NavLinkPlus />
									<NavLinkText>About</NavLinkText>
								</NavLink>
							</Nav>
						</Headroom>
						<Hamburger
							black={(!fixedFooter && bottom) || blackPlus}
							onClick={() =>
								setSiteState((prevState) => ({
									...prevState,
									menuOpen: !siteState.menuOpen,
								}))
							}
							open={siteState.menuOpen}
						/>
					</Inner>
				</Section>
			</Wrap>
			<MobileMenu open={siteState.menuOpen} data={data} />
		</>
	);
};

const Wrap = styled.header`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 104;
	left: 0;
	pointer-events: none;
	height: 100vh;
	min-height: -webkit-fill-available;
	min-height: fill-available;
	max-height: -webkit-fill-available;
	max-height: fill-available;
	overflow: ${(props) => (props.sticky ? 'visible' : 'hidden')};
	isolation: isolate;
	mix-blend-mode: initial;
`;
const Nav = styled.nav`
	font-size: 16px;
	display: flex;
	position: fixed;
	top: 22px;
	right: 31px;
	max-height: 35px;
	opacity: ${(props) => (props.show ? '1' : '0')};
	transition: opacity 0.2s;
	${tablet} {
		display: none;
	}
`;
const Inner = styled.div`
	grid-column: span 12;
	display: flex;
	padding-top: 23px;
	justify-content: space-between;
	${mobile} {
		padding-top: 19px;
	}
`;
const Left = styled(Link)`
	position: relative;
	z-index: 1;
	display: block;
`;
const LogoSplitOne = styled(MrPlus)`
	width: 61px;
	margin-top: 6px;
	margin-left: -5px;
	position: fixed;
	pointer-events: all;
	display: hidden;
	.mr {
		opacity: 0;
	}
	.plus {
		color: ${(props) => (props.black ? 'var(--black)' : 'var(--pink)')};
		opacity: 1;
		isolation: isolate;
		display: block;
		transition: color 0.3s;
	}
	${mobile} {
		width: 49px;
	}
`;
const LogoSplitTwo = styled(Positive)`
	width: 110px;
	margin-top: 6px;
	pointer-events: all;
	margin-left: 57px;
	opacity: 0;
	transition: opacity 0.2s;
	${mobile} {
		width: 91px;
		margin-left: 46px;
	}
`;
const StickyContainer = styled.div`
	position: relative;
	height: calc(100vh - 22px);
`;
const PageTitle = styled.div`
	display: inline-block;
	margin-left: 60px;
	position: ${(props) => (props.sticky ? 'sticky' : 'fixed')};
	top: 27px;
	margin-bottom: 24px;
	pointer-events: all;
	opacity: 0;
	transition: opacity 0.2s;
	max-width: calc(50vw - 90px);
	box-sizing: border-box;
	${tablet} {
		margin-right: 55px;
		margin-top: ${(props) => (props.sticky ? '-6px' : '0px')};
	}
	${mobile} {
		top: 23px;
		margin-left: 50px;
		max-width: calc(100% - 100px);
		width: 100%;
	}
`;
const NavLink = styled(Link)`
	margin-left: 21px;
	pointer-events: all;
	transition: opacity 0.2s;
	opacity: 0;
	color: var(--pink);
	svg {
		transform: translateY(120%);
		opacity: 0;
	}
	&.active {
		opacity: 1;
		svg {
			transition: transform 0s, opacity 0.25s;
			transform: translateY(0%);
			opacity: 1;
		}
	}
	:hover {
		color: var(--pink);
		opacity: 1;
		svg {
			transform: translateY(0%);
			opacity: 1;
		}
	}
`;
const NavLinkPlus = styled(Plus)`
	max-width: 16px;
	transition: transform 0.25s, opacity 0.25s;
	position: absolute;
	top: 6px;
`;
const NavLinkText = styled.span`
	display: inline-block;
	margin-left: 20px;
`;

Header.propTypes = {
	className: PropTypes.string,
	home: PropTypes.bool,
	fixedTitle: PropTypes.bool,
	fadeTitle: PropTypes.bool,
	blackPlus: PropTypes.bool,
	fixedFooter: PropTypes.bool,
	black: PropTypes.bool,
};

export default Header;
