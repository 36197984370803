module.exports = {
	siteUrl: 'https://mrpositive.work',
	buildsUrl: 'https://mrpositive.gtsb.io',
	previewUrl: 'https://preview-mrpositive.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/fd084f27-06b9-424f-b6fa-391eb456226a',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-5PEHCQ9GT2'
}
