import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import { Link } from 'gatsby'
import SanityLink from '~components/SanityLink'
import { mobile } from '~styles/global'
import RichText from '~components/RichText'

const Footer = ({ className, fixed, color, faded, minimal, whiteBg }) => {
	const { phoneNumber, emailAddress, instagramUrl, footerTextDesktop, footerTextMobile } = useSiteSettings()

	return (
		<Wrap className={className} fixed={fixed} minimal={minimal} whiteBg={whiteBg}>
			{!fixed && !minimal && (
				<Top>
					<Col className="logo-size">
						<Mobile>
							{footerTextMobile && <RichText content={footerTextMobile} />}
						</Mobile>
						<Desktop>
							{footerTextDesktop && <RichText content={footerTextDesktop} />}
						</Desktop>
					</Col>
				</Top>
			)}
			<Bottom color={color} show={!fixed} faded={faded}>
				<BottomSection minimal={minimal}>
					<Border color={color} faded={faded} />
					<Links className="sans-xs" minimal={minimal}>
						<PageLinks minimal={minimal}>
							{/* <Archivelink to={`/${data?.sanityArchivePage?.slug?.current}`}>Archive</Archivelink> */}© Mr+Positive {new Date().getFullYear()}
						</PageLinks>
						<Details minimal={minimal}>
							<Tel href={`tel:${phoneNumber}`}>{phoneNumber}</Tel>
							<Email
								minimal={minimal}
								href=""
								link={{
									url: `mailto:${emailAddress}`,
								}}
							>
								{emailAddress}
							</Email>
							<Insta
								link={{
									linkType: 'external',
									blank: true,
									url: instagramUrl,
								}}
							>
								Instagram
							</Insta>
							<Insta
								link={{
									linkType: 'external',
									blank: true,
									url: 'https://www.superclusterglobal.com/',
								}}
							>
								Supercluster
							</Insta>
							<Insta
								link={{
									linkType: 'external',
									blank: true,
									url: 'https://www.globalproductionnetwork.com/',
								}}
							>
								GPN
							</Insta>
						</Details>
					</Links>
				</BottomSection>
			</Bottom>
		</Wrap>
	)
}

const Wrap = styled.footer`
	position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
	background-color: ${(props) => (props.whiteBg ? 'var(--white)' : 'transparent')};
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 5;
	display: ${(props) => (props.fixed ? 'block' : 'grid')};
	mix-blend-mode: difference;
	${(props) => !props.fixed && 'grid-template-rows: 1fr max-content;'}
`
const Top = styled('div')`
	padding: 20px 30px;
	text-wrap: pretty;
	${mobile} {
		padding: 20px;
	}
`
const Col = styled.div`
	grid-column: span 6;
	${mobile} {
		grid-column: span 10;
		margin-bottom: 30px;
		p {
			margin-bottom: 0.9em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`
const Mobile = styled.div`
	display: none;
	${mobile} {
		display: block;
	}
`
const Desktop = styled.div`
	${mobile} {
		display: none;
	}
`
const Bottom = styled.div`
	height: 52px;
	color: ${(props) => (props.faded ? '#666666' : `var(--${props.color})`)};
	a {
		transition: color 0.25s;
		:hover {
			color: ${(props) => (props.faded ? 'var(--pink)' : `var(--${props.color})`)};
		}
	}
	${mobile} {
		height: auto;
		display: ${(props) => (props.show ? 'block' : 'none')};
	}
`
const BottomSection = styled(Section)``
const Border = styled.div`
	grid-column: span 12;
	height: 1px;
	background-color: ${(props) => (props.faded ? '#666666' : `var(--${props.color})`)};
	margin-bottom: 14px;
	${mobile} {
		margin-bottom: 12px;
	}
`
const Links = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: space-between;
	${mobile} {
		margin-bottom: 30px;
		flex-wrap: wrap;
		font-size: ${(props) => (props.minimal ? '12px' : '20px')};
		width: 100%;
	}
`
const PageLinks = styled.div`
	${mobile} {
		margin-bottom: 16px;
		display: ${(props) => (props.minimal ? 'none' : 'block')};
	}
`
const AboutLink = styled(Link)`
	margin-right: 32px;
	${mobile} {
		display: block;
		width: 100%;
		margin-bottom: 16px;
		margin-right: 0;
	}
`
const Archivelink = styled(AboutLink)`
	margin-right: 0;
`
const Details = styled.div`
	display: flex;
	${mobile} {
		flex-wrap: ${(props) => (props.minimal ? 'nowrap' : 'wrap')};
		justify-content: ${(props) => (props.minimal ? 'space-between' : 'flex-start')};
		width: 100%;
		column-gap: 16px;
	}
`
const Tel = styled.a`
	margin-left: 31px;
	${mobile} {
		margin-left: 0;
		margin-bottom: 19px;
	}
`
const Email = styled(SanityLink)`
	margin-left: 31px;
	${mobile} {
		margin-left: 0;
		margin-bottom: 16px;
		display: block;
		width: ${(props) => (props.minimal ? 'auto' : 'minimal')};
	}
`
const Insta = styled(SanityLink)`
	margin-left: 31px;
	${mobile} {
		margin-left: 0;
		margin-bottom: 16px;
		display: block;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
	fixed: PropTypes.bool,
	color: PropTypes.string,
	faded: PropTypes.bool,
}

export default Footer
