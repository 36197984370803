import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				mobileMenuBackgroundImage {
					...imageWithAlt
				}
      }
			sanityFooter {
				phoneNumber
				emailAddress
				instagramUrl
				footerTextDesktop: _rawFooterTextDesktop(resolveReferences: {maxDepth: 5})
				footerTextMobile: _rawFooterTextMobile(resolveReferences: {maxDepth: 5})
			}
    }
    `)

	return {...siteSettings?.sanitySiteSettings, ...siteSettings?.sanityFooter}
}

export default useSiteSettings
