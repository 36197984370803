import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {mobile, tablet} from '~styles/global';
import {MenuPlus} from '~components/Svg';

const Hamburger = ({className, open, onClick, black}) => {
	return (
		<Wrap className={className} onClick={onClick} black={black}>
			<Icon open={open} />
		</Wrap>
	);
};

const Wrap = styled.button`
	width: 31px;
	height: 31px;
	color: ${(props) => (props.black ? 'black' : 'white')};
	position: fixed;
	z-index: 1;
	pointer-events: all;
	top: 22px;
	right: 20px;
	display: none;
	${tablet} {
		display: block;
	}
`;

const Icon = styled(MenuPlus)`
	transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
	transition: transform 0.3s;
`;

Hamburger.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClick: PropTypes.func,
	black: PropTypes.bool,
};

export default Hamburger;
