import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ImageWithCaption from '~components/ImageWithCaption'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return (
					<Link 
						to={resolveLink(props.mark.document)} 
						css={css`
							text-decoration: underline; 
							text-decoration-thickness: 0.06em; 
							transition: color 0.2s ease, text-decoration-color 0.2s ease;
							&:hover{
								color: var(--pink);
								text-decoration-color: var(--pink);
							}
						`}>
						{props.children}
					</Link> )
			} else {
				return (
					<a 
						href={props.mark?.url} 
						css={css`
							text-decoration: underline; 
							text-decoration-thickness: 0.06em; 
							transition: color 0.2s ease, text-decoration-color 0.2s ease;
							&:hover{
								color: var(--pink);
								text-decoration-color: var(--pink);
							}
						`} 
						target={props.mark.blank ? '_blank' : '_self'} 
						rel='noreferrer'>
						{props.children}
					</a>)
			}
		},
	},
	types: {
		block: (props) => {
			if (props.children[0] === '') {
				return <br/>
			}
			if (props.node.style === 'h1') {
				return <h1>{props.children}</h1>
			}
			if (props.node.style === 'h2') {
				return <H2 className='sans'>{props.children}</H2>
			}
			if (props.node.style === 'normal') {
				return <p>{props.children}</p>
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
		image: ({node}) => {
			return (
				<>
					{console.log('node', node)}
					<ImageWithCaption image={node.props.iamge}/>
				</>
			)
		},
	}
}

const H2 = styled.h2`
	margin-bottom: 20px;
`

const RichText = ({ content }) => <BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>


RichText.propTypes = {
	content: PropTypes.array,
	pinkLinks: PropTypes.bool
}

export default RichText
