import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'

const ImageWithCaption = ({ className, image }) => {
	return (
		<Wrap className={className}>
			<Image image={image}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`

ImageWithCaption.propTypes = {
	className: PropTypes.string,
	image: PropTypes.object,
}

export default ImageWithCaption